import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import ImageLayout from "../components/image-layout"
import Layout from "../components/layout"
import Card from "../components/card"
import CardContact from "../components/card-contact"
import SEO from "../components/seo"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background/contact.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1920) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
      map1: file(relativePath: { eq: "map-marseille.png" }) {
				childImageSharp {
					fluid(maxWidth: 1920) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
      map2: file(relativePath: { eq: "map-bandol.png" }) {
				childImageSharp {
					fluid(maxWidth: 1920) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
      pictoAddress: file(relativePath: { eq: "icons/contact/address-icon.png" }) {
				childImageSharp {
          fixed(width: 32, height: 32) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Contact" />
      <ImageLayout text="Contact" image={data.background.childImageSharp.fluid} />
      <section className="bg-grey">
      <Card title="essere avocats" text="Contact" position="left" />
        <Grid container>
          <Grid item xs={12} md={6}>
          <CardContact 
                name="ESSERE AVOCATS Marseille"
                mail="jf.marchi@essere-avocats.com"
                phone="04 84 25 86 81"
                fax="04 84 25 86 78"
                cabinet="6 cours Pierre Puget
                13006 MARSEILLE" />
                <Grid id="map-wrapper" item xs={12} md={7}>
                  <a 
                    href="https://www.google.com/maps/place/6+Cr+Pierre+Puget,+13006+Marseille/@43.2906976,5.3752652,17z/data=!3m1!4b1!4m5!3m4!1s0x12c9c0b9e576d531:0xbe662fadeb572f7f!8m2!3d43.2906976!4d5.3774539" 
                    target="_blank" 
                    rel="noopener noreferrer">
                      <Img fluid={data.map1.childImageSharp.fluid} />
                  </a>
                </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
          <CardContact 
                name="ESSERE AVOCATS Bandol"
                mail="o.ricciotti@essere-avocats.com"
                phone="04 84 25 86 71"
                fax="04 84 25 86 70"
                cabinet="3 Place Estienne d’Orves
                83150 BANDOL" />
                <Grid id="map-wrapper" item xs={12} md={7}>
                  <a 
                    href="https://www.google.com/maps/place/Cabinet+ESSERE+AVOCATS/@43.1364264,5.752204,17z/data=!3m1!4b1!4m5!3m4!1s0x12c9071270a40001:0x2f4ae0d21e15fa25!8m2!3d43.1364264!4d5.7543927" 
                    target="_blank" 
                    rel="noopener noreferrer">
                      <Img fluid={data.map2.childImageSharp.fluid} />
                  </a>
                </Grid>
          </Grid>
        </Grid>
      </section>
    </Layout>
  )
}

export default ContactPage
